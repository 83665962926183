<template>
  <div
    v-if="showAlert"
    :class="alertClass"
    role="alert"
  >
    <div
      v-if="mAlert"
      class="message-wrap"
    >
      <div class="icon" />
      <div class="message">
        <div class="title">{{ message }}</div>
        <slot name="sub-message">
          <div v-if="subMessage">{{ subMessage }}</div>
        </slot>
      </div>
    </div>
    <div
      v-if="mAlert && withDetails"
      class="details"
    >
      <hr>
      <slot />
    </div>
    <template v-if="!mAlert">
      {{ message }}
      <button
        v-if="dismissable"
        type="button"
        class="close"
        aria-label="Close"
        @click.prevent="onDismissClick"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <template v-if="showDetails">
        <hr>
        <div v-html="detailsHtml" />
        <slot />
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue'
import { isNonEmptyString } from '@/src/js-utils'

interface Props {
  mAlert?: boolean
  type?: string
  dismissable?: boolean
  message?: string | null
  subMessage?: string | null
  detailsHtml?: string | null
  withDetails?: boolean
}

const emit = defineEmits<{
  (e: 'dismiss'): void
}>()

const props = withDefaults(defineProps<Props>(), {
  mAlert: false,
  type: 'danger',
  dismissable: true,
  message: null,
  subMessage: null,
  detailsHtml: null,
  withDetails: false,
})

const slots = useSlots()

const alertClass = computed((): string => {
  if (props.mAlert) {
    return `m-alert m-alert-${props.type}`
  }
  return `alert alert-sm alert-${props.type} alert-dismissible fade show`
})
const showAlert = computed(() => isNonEmptyString(props.message))
const showDetails = computed(() => slots.default != null || isNonEmptyString(props.detailsHtml))

const onDismissClick = () => emit('dismiss')
</script>
