<template>
  <div
    v-if="visible"
    ref="modal"
    :class="['modal fade m-modal', { 'no-footer': !withFooter }, modalCssClass]"
    :data-autofocus="autofocus ? null : 'false'"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div
      :class="['modal-dialog', modalDialogCssClass]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5
            id="modalLabel"
            class="modal-title"
          >
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div
          v-if="hasSubTitle"
          class="modal-sub-title"
        >
          {{ modalSubTitle }}
        </div>
        <div class="modal-body">
          <slot name="modal-content" />
        </div>
        <div
          v-if="withFooter"
          class="modal-footer"
        >
          <slot name="modal-footer">
            <button
              type="button"
              class="m-btn m-btn-cancel"
              data-dismiss="modal"
            >
              {{ $t('modal.close_button') }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import $ from 'jquery'
import { isNonEmptyString } from '@/src/js-utils'
import { computed, nextTick, ref } from 'vue'

interface Props {
  modalTitle?: string
  modalSubTitle?: string | null
  modalCssClass?: string | null
  modalDialogCssClass?: string | null
  withFooter?: boolean
  autofocus?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modalTitle: '',
  modalSubTitle: null,
  modalCssClass: null,
  modalDialogCssClass: null,
  withFooter: true,
  autofocus: true
})

const emit = defineEmits<{
  (e: 'modal-hidden'): void
  (e: 'modal-shown'): void
}>()

const modal = ref<HTMLDivElement | null>(null) // ref to modal <div>
const modalVisible = ref(false)

const visible = computed(() => modalVisible.value)
const hasSubTitle = computed(() => isNonEmptyString(props.modalSubTitle))

const show = (): void => {
  if (modalVisible.value) return

  modalVisible.value = true

  // show modal on next render
  nextTick(() => {
    const dialogEl = modal.value
    if (dialogEl instanceof HTMLDivElement) {
      $(dialogEl).on('hidden.bs.modal', () => {
        modalVisible.value = false
        emit('modal-hidden')
      })

      $(dialogEl).on('shown.bs.modal', () => {
        emit('modal-shown')
      })

      // show modal
      $(dialogEl).modal({
        keyboard: false,
        backdrop: 'static'
      })
    }
  })
}

const hide = (): void => {
  const dialogEl = modal.value
  if (dialogEl instanceof HTMLDivElement) {
    $(dialogEl).modal('hide')
  }
}

defineExpose({
  show,
  hide,
  visible
})
</script>
